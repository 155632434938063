@import "../style.scss";

.FullScreenLayout {
  background-color: $color-bg;
  width: 100vw;
  min-height: 100vh;

  > .content {
    margin: 0 auto;
    padding-top: 160px;
    padding-bottom: 60px;
    width: 850px;
  }

  &.narrow {
    > .content {
      width: 450px;
    }
  }
}