@import "../style.scss";

.SelectField {
  display: flex;
  flex-direction: column;

  > .row {
    display: flex;

    > .label {
      flex-grow: 1;
      flex-shrink: 0;
      margin-bottom: 10px;
    }

    > .error {
      color: $color-2;
    }
  }

  > .select {
    background-color: $color-bg;
    border: solid 2px $color-bg;
    border-radius: $border-radius;
    height: 40px;
    outline: none;
    padding: 10px;
    color: $color-text;

    &:active,
    &:focus {
      border-color: $color-1;
    }
  }

  &.error {
    > .select {
      color: $color-2;
    }
  }

  &.placeholder {
    > .select {
      color: $color-text-minor;
    }
  }
}