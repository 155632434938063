@import '../style.scss';

.Button {
  background-color: $color-1;
  border: solid 2px $color-1;
  color: $color-bg-highlight;
  height: 40px;
  border-radius: $border-radius;
  padding: 0em 1em;
  display: inline-flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  &:active {
    background-color: desaturate($color-1, 30%);
    border-color: desaturate($color-1, 30%);
  }

  &:hover {
    cursor: pointer;
  }

  &[disabled] {
    background-color: lighten($color-1, 10%);
    border-color: lighten($color-1, 10%);
    cursor: not-allowed;
  }

  &.small {
    height: 24px;
    padding: 0em 0.5em;
  }

  &.minor {
    background-color: transparent;
    color: $color-1;

    &[disabled] {
      color: lighten($color-1, 10%);
    }
  }

  &.block {
    display: flex;
    width: 100%;
  }
}