@import '../style.scss';

.OptionButton {
  display: inline-flex;
  flex-direction: column;
  background-color: $color-bg;
  border: solid 2px $color-bg;
  border-radius: $border-radius;
  padding: 10px 15px;
  outline: none;
  text-align: left;

  &:active {
    filter: brightness(95%);
  }

  > .extra {
    color: $color-text-minor;
  }

  > .children {
    word-break: break-all;
  }

  &.selected {
    border-color: $color-1;
  }

  &.block {
    display: flex;
    width: 100%;
  }
}
