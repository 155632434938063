.ContentLayout {
  width: 100%;

  > .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    width: 100%;

    > .left {
      flex-grow: 1;
      width: 0;
      margin-right: 15px;
    }

    > .right > * + * {
      margin-left: 15px;
      flex-shrink: 0;
    }
  }

  > .content {
    > * + * {
      margin-top: 30px;
    }
  }

  > .footer {
    margin-top: 60px;
    
    > p {
      margin-bottom: 1em;
    }
  }
}