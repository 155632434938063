@import '../style.scss';

.Headline {
  font-size: 18px;
  margin-bottom: 12px;
  width: 100%;

  > .extra {
    font-size: 14px;
    color: $color-text-minor;
    margin-left: 1em;
  }

  > .sub {
    font-size: 14px;
    margin-top: 0.2em;
    color: $color-text-minor;
  }

  > .children {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &.large {
    font-size: 22px;

    > .extra {
      font-size: 16px;
    }
  }
}