@import './style.scss';

.Upload {
  display: flex;
  height: 100px;
  width: 100%;
  background-color: $color-bg-highlight;
  border: dashed $border-width $color-1;
  border-radius: $border-radius;
  padding: 10px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: $color-1;

  &__input {
    display: none;
  }

  &__icon {
    font-size: 32px;
  }

  &__content {
    line-height: 0;

    > * + * {
      margin-left: 10px;
    }
  }

  &__bar {
    width: 100%;
    height: 10px;
    background-color: $color-4;
    border-radius: $border-radius;
  }

  &__barInner {
    width: 0%; // set by component
    height: 10px;
    background-color: $color-1;
    border-radius: $border-radius;
  }

  &__label {
    // font issue
    vertical-align: 50%;
    line-height: 1;
  }

  &.isUploadInProgress {
    color: $color-text;
    border: solid $border-width $color-1;;

    .Upload__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > * + * {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}