@import '../style.scss';

.Label {
  display: inline-flex;
  background-color: $color-1;
  border-radius: 99999px;
  height: 18px;

  > .name {
    color: $color-bg-highlight;
    padding-left: 10px;
    padding-right: 5px;
    line-height: 18px;
  }

  > .amount {
    background-color: $color-bg-highlight;
    font-size: 12px;
    padding: 0 10px;
    border-radius: 99999px;
    margin: 2px;
    line-height: 16px;
  }

  &.primary {
    background-color: $color-2;
  }

  &.secondary {
    background-color: $color-3;
  }

  &.minor {
    filter: brightness(110%) saturate(90%);
  }

  &:not(.hasChildren) {
    padding-right: 5px;
    background-color: currentColor;
  }
}