.Canvas {
  $space-base: 15px;

  position: fixed; // This is a hotfix for a iPad scrolling issue
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  &__toolbar {
    display: flex;
    position: absolute;
    top: $space-base;
    left: $space-base;

    > * + * {
      margin-left: 15px;
    }
  }

  &__canvas {
    height: 100%;
    width: 100%;
  }
}