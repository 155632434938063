@import "../style.scss";

.UploadArea {
  display: flex;
  height: 100px;
  width: 100%;
  background-color: $color-bg-highlight;
  border: dashed $border-width $color-1;
  border-radius: $border-radius;
  padding: 10px;
  justify-content: center;
  align-items: center;
  font-size: 18px;

  > .content {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 10px;
    }

    > .icon {
      color: $color-1;
      font-size: 32px;
    }

    > .input {
      display: none;
    }
  }

  &.dragOver {
    border: solid $border-width $color-1;
  }

  &.uploadInProgress {
    color: $color-text;
    border: solid $border-width $color-1;

    > .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > * + * {
        margin-left: 0;
        margin-top: 15px;
      }

      > .bar {
        width: 100%;
        height: 10px;
        background-color: $color-bg;
        border-radius: $border-radius;

        > .inner {
          width: 0%; // set by component
          height: 10px;
          background-color: $color-1;
          border-radius: $border-radius;
        }
      }
    }
  }
}