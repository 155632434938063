@import "../style.scss";

.Form {
  display: flex;
  flex-direction: column;
  background-color: $color-bg-highlight;
  border-radius: 15px;
  padding: 30px 40px;

  > * + * {
    margin-top: 30px;
  }

  > .body {
    > * + * {
      margin-top: 20px;
    }
  }

  > .footer,
  > .header {
    display: flex;
    flex-direction: column;
  }

  > .footer {
    > * + * {
      margin-top: 20px;
    }
  }
}