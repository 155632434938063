@import '../style.scss';
@import './Button.scss';

.IconButton {
  @extend .Button;

  $size: 40px;
  $size-small: 18px;

  height: $size;
  width: $size;
  border: 0;
  color: $color-bg-highlight;
  line-height: 0;
  border-radius: 9999px;
  outline: 0;
  font-size: 24px;
  padding: 0;

  &.small {
    height: $size-small;
    width: $size-small;
    padding: 0;
    font-size: 14px;
  }

  &.minor {
    border: solid 2px $color-1;
  }
}