$color-1: hsl(226, 88%, 67%);
$color-2: hsl(354, 100%, 75%);
$color-3: hsl(124, 64%, 66%);
$color-4: hsl(43, 47%, 93%);
$color-bg: hsl(258, 30%, 97%);
$color-bg-highlight: hsl(0, 0%, 100%);
$color-text: hsl(0, 0%, 31%);
$color-text-minor: hsl(222, 12%, 64%);

$border-radius: 5px;
$border-width: 2px;