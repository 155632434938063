@import "../style.scss";

.Field {
  display: flex;
  flex-direction: column;

  > .row {
    display: flex;

    > .label {
      flex-grow: 1;
      flex-shrink: 0;
      margin-bottom: 10px;
    }

    > .error {
      color: $color-2;
    }
  }
}