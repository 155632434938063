@import "../style.scss";

.MainLayout {
  $footer-height: 80px;
  $header-height: 50px;
  $aside-width: 400px;

  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-columns: $aside-width auto;
  grid-template-rows: $header-height auto $footer-height;
  grid-template-areas:
    "asideHeader main"
    "aside       main"
    "asideFooter main";

  > .main {
    grid-area: main;
    padding: 30px;
    background-color: $color-bg;
    overflow: auto;


    > .content {
      max-width: 1024px;
      min-width: 768px;
      margin: 0 auto;
    }
  }
  
  > .aside {
    grid-area: aside;
    padding: 30px;
    overflow: auto;

    > p {
      margin-bottom: 1em;
    }

    > .OptionButton {
      margin-bottom: 20px;
    }
  }

  > .asideFooter {
    grid-area: asideFooter;
    padding: 20px;
  }

  > .asideHeader {
    grid-area: asideHeader;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 0px 30px;

    > .Headline {
      margin-bottom: 0;
    }
  }
}