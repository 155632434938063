@import '../node_modules/normalize.css/normalize.css';
@import '../node_modules/scut/dist/scut';

@import './style.scss';
@import './fonts/index.scss';

@include scut-reset-button;
@include scut-reset-border-box;
@include scut-reset-pointer;
@include scut-reset-form;

body {
  font-family: 'Sailec', sans-serif;
  color: $color-text;
  font-weight: 500;
  font-size: 14px;
  
  // * {
  //   box-sizing: border-box;
  // }

  width: 100vw;
  height: 100vh;
}

h2 {
  font-size: 18px;
  font-weight: 400;
}

a {
  color: $color-1;
}

p {
  color: $color-text-minor;
  margin: 0;
  line-height: 1.5;
}

hr {
  margin: 0;
  border: 0;
  border-top: 2px solid $color-bg;
}

.color1 {
  color: $color-1;
}

.minor {
  color: $color-text-minor;
  font-size: 14px;
}

.list {
  > * + * {
    margin-left: 15px;
  }
}

.columns {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 20px;
}

.disabledArea {
  opacity: 0.4;
  pointer-events: none;
}