@import '../style.scss';

.Table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  > thead > tr > th {
    text-align: left;
    color: $color-text-minor;
    padding: 0px 5px;
  }

  > tbody > tr {
    background-color: $color-bg-highlight;
  }

  > tbody > tr > td {
    padding: 15px 5px;
  }

  > tbody > tr > td,
  > thead > tr > th {

    &:first-child {
      padding-left: 30px;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      padding-right: 30px;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      text-align: right;
    }
  }

  > tbody > tr:not(:hover) .action {
    opacity: 0;
  }
}