@import '../style.scss';

.ToolbarItem {

  background-color: $color-bg-highlight;
  outline: 0;
  padding: 8px;
  border: solid 2px $color-text-minor;
  color: $color-text-minor;
  height: 44px;

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &:not(:first-child) {
    border-left: 0;
  }

  &.selected, &:active {
    background-color: $color-text-minor;
    color: $color-bg;
  }

  > * {
    vertical-align: middle;
  }
}